import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import axios from 'axios';
import React, { Suspense, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router-dom';
import AuthRequiredModal from './components/authGuard/AuthRequiredModal';
import CorsErrorModal from './components/authGuard/CorsErrorModal';
import { AuthContext } from './contexts/auth';
import { API_DOMAIN, authConfig } from './globals';
import Loader from './layouts/loader/Loader';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Themeroutes from './routes/Router';
import { setMetadata } from './store/metadata/MetadataSlice';

const METADATA = `${API_DOMAIN}/vision-api/metadata`;

const App = () => {
  const routing = useRoutes(Themeroutes);
  const navigate = useNavigate();
  const oktaAuth = useContext(AuthContext);
  const dispatch = useDispatch();

  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);

  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  const triggerLogin = async () => {
    return oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();

    if (previousAuthState?.isAuthenticated) {
      setAuthRequiredModalOpen(true);
    } else {
      await triggerLogin();
    }
  };

  const fetchMetadata = async () => {
    const idTokenResponse = await oktaAuth.tokenManager.get('idToken');
    const { data } = await axios.get(METADATA, {
      headers: { Authorization: `Bearer ${idTokenResponse.idToken}` },
    });

    dispatch(setMetadata(data));
  };

  useEffect(() => {
    if (oktaAuth) fetchMetadata();
  }, [oktaAuth]);

  return (
    <Suspense fallback={<Loader />}>
      <div
        data-testid="app-container"
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <CorsErrorModal
            issuer={authConfig.issuer}
            {...{ corsErrorModalOpen, setCorsErrorModalOpen }}
          />
          <AuthRequiredModal
            {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }}
          />
          {routing}
        </Security>
      </div>
    </Suspense>
  );
};

export default App;
